/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useCallback, useRef } from 'react';
import ReactCanvasConfetti from "react-canvas-confetti";
import shuffle from 'lodash.shuffle'

import './App.css';
import gift1 from './gift1.png'
import gift2 from './gift2.png'


const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};
const rewards = shuffle(['1.000.000€', 'Počitnice na Havajih', 'Doživljenska zaloga poli salame', 'Nov računalnik v vrendosti 3000€', 'Peugeot 2008', 'Nič', 'Kilogram sira'])

const finalRewardsArray = []
function App() {
  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [showOverlay, setShowOverlay] = useState(false)
  const [finalLink, setFinalLink] = useState()
  const [currentReward, setCurrentReward] = useState('')
  const [rewardTypeText, setRewardTypeText] = useState('Žrebam darilo:')
  const input = useRef(null)
  const checkCode = () => {
    if (code.toLowerCase().trim().replace(regex, '') === '14n4-84nan4-z42p4na'.replace(regex, '')) {
      const finalReward = 'Bon v vrednost 40€ za nakup kolesarske opreme'
      finalRewardsArray.push(...rewards, finalReward)
      setShowOverlay(true)
    } else if (code.toLowerCase().trim().replace(regex, '' ) === 'n1n1-24-20j57n1-d4n'.replace(regex, '')) {
      const finalReward = 'Kuverta z 20€ in plačilo računa za tortico, ki bo prišel'
      finalRewardsArray.push(...rewards, finalReward)
      setShowOverlay(true)
    } else if (code.toLowerCase().trim().replace(regex, '' ) === 'jj3v-5m4r715-kv1z-2022'.replace(regex, '')) {
      const finalReward = 'magični PDF!'
      const newRewards = shuffle([...rewards, 'Nova pogodba s petrolom', 'Priložnost dela na SmartDPO', 'Izbira kam se gre na malico'])
      finalRewardsArray.push(...newRewards, finalReward)
      setShowOverlay(true)
    } else if (code.toLowerCase().trim().replace(regex, '' ) === '4r14n1-z4-d3dk4-mr4z4'.replace(regex, '')) {
      const finalReward = 'BON ZA WOLT!'
      const newRewards = shuffle([...rewards, 'Vagina', 'All the money', 'Neomejeno število nakupov pri H&M'])
      finalRewardsArray.push(...newRewards, finalReward)
      setShowOverlay(true)
    } else if (code.toLowerCase().trim().replace(regex, '' ) === '570j4nu-z4-d3dk4-mr4z4'.replace(regex, '')) {
      const finalReward = 'BON ZA WOLT!'
      const newRewards = shuffle([...rewards, 'All the gays', 'Objem', 'Idealni anti depresivi'])
      finalRewardsArray.push(...newRewards, finalReward)
      setShowOverlay(true)
    } else if (code.toLowerCase().trim().replace(regex, '' ) === 'al35u-24-20j57n1-d4n'.replace(regex, '')) {
      const finalReward = 'POLAR!'
      const newRewards = shuffle([...rewards, 'Nova börmašina', 'Čoln', '30 ton arašidov'])
      finalRewardsArray.push(...newRewards, finalReward)
      setShowOverlay(true)
    } else if (!code) {
      setError('Manjka koda')
    } else {
      setError('Napačna koda')
    }
  }
  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);
  const regex= /-/gm
  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  useEffect(() => {
    const showRewards = async () => {
      if (showOverlay) {
        for (let i = 0; i < finalRewardsArray.length; i++) {
          await new Promise((resolve) => {
            setCurrentReward(finalRewardsArray[i])
            const randomTime = Math.random() * (1000- 300) + 300
            setTimeout(() => {
              resolve()
            }, randomTime)
          })
        }
        setRewardTypeText('Izžrebano darilo je:')
        if (code.toLowerCase().trim().replace(regex, '' ) === 'jj3v-5m4r715-kv1z-2022'.replace(regex, '')) {
          setFinalLink(<><br></br><a href="/magicniList.pdf">KLIKNI ZA NAGRADO</a></>)
        } else if (code.toLowerCase().trim().replace(regex, '' ) === 'al35u-24-20j57n1-d4n'.replace(regex, '')) {
          setFinalLink(<><br></br><a href="/darile1.pdf">KLIKNI ZA DARILO</a></>)
        } else if (code.toLowerCase().trim().replace(regex, '' ) === '570j4nu-z4-d3dk4-mr4z4'.replace(regex, '')) {
          setFinalLink(<><br></br><a href="/wolt1.pdf">KLIKNI ZA DARILO</a></>)
        } else if (code.toLowerCase().trim().replace(regex, '' ) === '4r14n1-z4-d3dk4-mr4z4'.replace(regex, '')) {
          setFinalLink(<><br></br><a href="/wolt2.pdf">KLIKNI ZA DARILO</a></>)
        }
        fire()

      }
    }
    showRewards()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ showOverlay])
  return (
    <div className="App">
      {showOverlay && <div className='overlay'>
        <span>
          {rewardTypeText}
        </span>
        <span>
          {currentReward}
          {finalLink}
        </span>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </div>}
      <div className='title'>DARILE!!!!!</div>
      <div className='body'>
        <img src={gift1} height='150' className="rotate"></img>
        <div className='input-container'>
          <input 
            ref={input}
            type='text' 
            placeholder='Vpišite kodo in pritisni enter'
            value={code}
            onChange={(e) => {
                setError('')
                setCode( e.target.value)
              }
            }
            onKeyDown={(e)=> {
              if ((e.key === 'Enter' || e.key === 'NumpadEnter')) {
                checkCode()
              }
            }}
            onSubmit={() => {
              checkCode()
            }}
          ></input>
          <span className='error'>{error}</span>
          <button className='search-button' onClick={() => {
            checkCode()
          }}>Preveri kodo</button>
        </div>
        <img src={gift2} height='150' className="rotate-2"></img>
      </div>
    </div>
  );
}

export default App;
